<template>
  <b-card-code
    title=""
    style="margin-top: 30px"
  >
   <div class="container-fluid pt-3">
    <div class="row">
      <div class="col-md-3 mb-1">
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :text="searchColumn == null ? 'الخانه' : searchColumn"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="column in columns"
            :key="column.field"
             :value="column.field" @click="selectedFilterColumn=column.field,searchColumn = column.label">
          
            {{ column.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
     <div class="col-md-3 mb-1">
  <b-input-group>
    <b-form-input
      v-model="filterValue"
      @keyup.enter="handleFilter"
      placeholder="القيمه"
    ></b-form-input>
    <b-input-group-append>
      <b-button @click="handleFilter" variant="outline-secondary">
         <feather-icon icon="SearchIcon"></feather-icon>
      </b-button>
    </b-input-group-append>
    <b-input-group-append>
      <b-button @click="searchColumn=null,filterValue=null,GetRowData()" variant="outline-secondary">
         <feather-icon icon="XIcon"></feather-icon>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</div>

    </div>
  </div>
    <!-- table -->
    <vue-good-table
      :key="tableRenderKey"
      :columns="columns"
      :rows="reportData"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >

      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'Action'">
        <button
            style="margin-right: 16px"
            class="btn btn-primary"
            v-b-tooltip.hover.right="' تعديل الكومنت'"
            @click="EditComment(props.row.Id)"
          >
          <feather-icon
              icon="EditIcon"
              size="12"
            />
           </button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope=""
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select
              v-model="pageLength"
              :options="['3', '7', '10', '15', '20']"
              class="mx-1"
               @change="onPerPageChange"
            />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            
            <b-pagination
               v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />

              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    VBTooltip,
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
   BCol,
  BInputGroupAppend, 
  BInputGroup,
  BIcon
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    VBTooltip,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
      BInputGroupAppend, 
  BInputGroup,
  BIcon
  },

  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created(){
  this.GetReportItems();
  this.GetRowData();
},

  data() {
    return {
      selectedFilterColumn: null, // Tracks the currently selected column for filtering
      filterValue: null, // The global filter value
      searchColumn:null,
     admins:[],
       pageLength: 7,
      currentPage :1,
       totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      reportItems:[],
      columns: [],
      reportData:[],
      searchTerm: '',
       tableRenderKey:0
    }
  },
  methods: {
    async handleFilter() {
      // Example: Fetch filtered data from your API
      await this.GetRowData(this.selectedFilterColumn, this.filterValue);
      
    },
    async GetReportItems(){
        try {
           let reportId = {
      label:'رقم المسلسل',
      field:'Id',
     
    } 
        var response = await this.$store.dispatch('GetReportItems');
       if(response.status = 200){
         this.columns.push(reportId)
       this.reportItems = response.data;
    for(var i=0;i<this.reportItems.length;i++){
        let obj = {
            label:this.reportItems[i].arabicName,
            field:this.reportItems[i].name,
           
        }
        this.columns.push(obj)
    }
    let agentCommentObj = {
      label:'ملاحظات المندوب',
      field:'AgentComment',
    } 
    let companyCommentObj =  {
            label:'ملاحظات الشركة',
            field:'CompanyComment', 
        }

    let actionObj =  {
            label:'التفاصيل',
            field:'Action', 
        }
        
        this.columns.push(agentCommentObj)
        this.columns.push(companyCommentObj)
     
        this.columns.push(actionObj)
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
      },
 async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10);
      await this.GetRowData(this.selectedFilterColumn, this.filterValue);
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.GetRowData(this.selectedFilterColumn, this.filterValue);
    },
      async GetRowData(filterKey,filterValue){
         if(filterKey != null ||  filterValue != null){
            this.SearchData(filterKey,filterValue);
         }
         else{
         const payload = {
           page:this.currentPage,
        pageSize:this.pageLength
        };
          try {
        const response = await this.$store.dispatch("GetFullReportData",payload);
        if ((response.status = 200)) {
          this.reportData = response.data.result;
          this.tableRenderKey++;
            this.totalCount = response.data.totalCountData;
        this.totalPages = response.data.pagesCount;
        }
      } catch (err) {
          this.$toast({
            component: ToastificationContent,
             props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          });
        
      }
      }
      },
      async SearchData(filterKey,filterValue){
        const payload = {
           page:this.currentPage,
        pageSize:this.pageLength,
        filterKey:filterKey,
        filterValue:filterValue
        };
          try {
            
        const response = await this.$store.dispatch("SearchFullReportData",payload);

        if ((response.status = 200)) {
          this.reportData = response.data.result;
            this.totalCount = response.data.totalCountData;
        this.totalPages = response.data.pagesCount;
        }
      } catch (err) {
          this.$toast({
            component: ToastificationContent,
             props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          });
      }
      
          
      },
  EditComment(id){
    this.$router.push({
      name:'edit-agent-comment',
      params:{id:id}
    })
  }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

}
</script>
<style>
.dropdown-menu.show {
  max-height: 250px;
  overflow-y: scroll;
}
</style>
<style scoped>
.vgt-left-align,.sortable{
    max-width:120px
}
</style>
